@import '~antd/dist/antd.css';
.MuiFab-root {
    position: fixed;
    right: 32px;
    bottom: 32px;
    z-index: 1;
    display: none;
}
.handlestackellipse{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    }
    @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
/* body::-webkit-scrollbar {
    width: 1em;
}
.jodit .jodit-dialog__panel
{
    width: 400px !important;
height: 80% !important;
background: linear-gradient(167.46deg,#3fbcaa,#4bc5b3 22.03%,#2a60a4 100.7%) !important;
}

.jodit .jodit-dialog__header
{
    border-bottom: 1px solid #3fbcaa  !important;
}

.jodit .jodit-dialog__content
{
    max-width: 380px !important;
}
.jodit  .jodit-context
{
    max-width: 400px !important;  
    min-width: 300px !important;
    text-align: center !important;
    margin-left: 22px !important;
    background: rgba(0, 0, 0, 0.25) !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    border-radius: 0.25rem !important;
}
.jodit .jodit-dialog__content .RichTextEditor__block___2Vs_D
{
    background: transparent !important;
}
.jodit .jodit-dialog__content  .public-DraftStyleDefault-ol
{
    background: transparent !important;
}

.jodit .jodit-dialog__content .public-DraftStyleDefault-ul
{

        background: transparent !important;
     
}

.jodit .jodit-dialog__content    .RichTextEditor__paragraph___3NTf9
{
    margin-left: 0px !important;
}

.jodit .public-DraftStyleDefault-ltr
{
    margin-left: 0px !important;  
} */
